import React, { useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureCurrentUser,
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  createNewTransaction,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';
import { brandOptionsIRF, budgetOptions, coolingTypes, fuelSourceOptions, generatorOptions, planOptions, reasonOptions, soonOptions, squareFootage } from '../../util/constants';

import css from './ListingPage.module.css';
import tickgreen from '../../assets/images/tick.png';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionHero from './SectionHero';
import SectionImages from './SectionImages';
import NewImagecarousel from './NewImageCarousel';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onCreateNewTransaction,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
  } = props;

  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingConfig = listingConfigProp || config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));
  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const { userType } = ensureCurrentUser(currentUser).attributes.profile.publicData || {};

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onCreateNewTransaction,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
      price: intl.formatNumber(convertMoneyToNumber(price), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      priceCurrency: price.currency,
    }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

  const {
    plan,
    soon,
    footage,
    generator,
    reason,
    budget,
    brand,
    fuelSource,
    hasPendingBids,
    type,
    appliances = {},
  } = publicData;

  const { appliance = [], houseHold = [], outdoor = [], utility = [] } = appliances;
  const filteredAppliances = [...appliance, ...houseHold, ...outdoor, ...utility].filter(
    appliance => appliance.count > 0
  );

  const images = currentListing?.attributes?.publicData?.pictureFields || [];
  const imageURLs = images.filter(i => i.imageURL).map(i => i.imageURL)

  const timeLine = currentListing.attributes.title;

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        <div className={css.contentWrapperForProductLayout}>
          <div className={css.mainColumnForProductLayout}>
            {/* <SectionImages
              title={title}
              listing={currentListing}
              isOwnListing={isOwnListing}
              editParams={{
                id: listingId.uuid,
                slug: listingSlug,
                type: listingPathParamType,
                tab: listingTab,
              }}
              imageCarouselOpen={imageCarouselOpen}
              onImageCarouselClose={() => setImageCarouselOpen(false)}
              handleViewPhotosClick={handleViewPhotosClick}
              onManageDisableScrolling={onManageDisableScrolling}
            /> */}
            {/* {userType === 'HO' ? <NewImagecarousel listing={currentListing} /> : null} */}

            {/* <SectionHero
              title={title}
              listing={currentListing}
              isOwnListing={isOwnListing}
              editParams={{
                id: listingId.uuid,
                slug: listingSlug,
                type: listingPathParamType,
                tab: listingTab,
              }}
              imageCarouselOpen={imageCarouselOpen}
              onImageCarouselClose={() => setImageCarouselOpen(false)}
              handleViewPhotosClick={handleViewPhotosClick}
              onManageDisableScrolling={onManageDisableScrolling}
            /> */}
            {currentListing.id && !hasPendingBids ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingPathParamType,
                  tab: listingTab,
                }}
              />
            ) : null}

            <div className={css.detailsWrapper}>
              <div className={css.detailsWrapperS1}>
                <p className={css.lisHeading}>
                  <FormattedMessage id="listingPageCrousal.detailsTitle" />
                </p>
                <div className={css.listWrapper}>
                  <div className={css.listDetail}>
                    <div className={css.listingLeft}>
                      <FormattedMessage id="listingPageCrousal.timelineTitle" />
                    </div>
                    <div className={css.subDetails}> <span> - </span>{soonOptions.find(s => s.key === soon)?.label}</div>
                  </div>
                  <div className={css.listDetail}>
                    <div className={css.listingLeft}>
                      <FormattedMessage id="listingPageCrousal.paymentTitle" />
                    </div>
                    <div className={css.subDetails}> <span> - </span>{planOptions.find(p => p.key === plan)?.label}</div>
                  </div>
                  <div className={css.listDetail}>
                    <div className={css.listingLeft}>
                      <FormattedMessage id="listingPageCrousal.reasonTitle" />
                    </div>
                    <div className={css.subDetails}> <span> - </span>{reasonOptions.find(r => r.key === reason)?.label}</div>
                  </div>
                  <div className={css.listDetail}>
                    <div className={css.listingLeft}>
                      <FormattedMessage id="listingPageCrousal.whoforTitle" />
                    </div>
                    <div className={css.subDetails}> <span> - </span>{generatorOptions.find(g => g.key === generator)?.label}</div>
                  </div>
                  <div className={css.listDetail}>
                    <div className={css.listingLeft}>
                      <FormattedMessage id="listingPageCrousal.footageTitle" />
                    </div>
                    <div className={css.subDetails}> <span> - </span>{squareFootage.find(f => f.key === footage)?.label}</div>
                  </div>
                  <div className={css.listDetail}>
                    <div className={css.listingLeft}>
                      <FormattedMessage id="listingPageCrousal.coverageTitle" />
                    </div>
                    <div className={css.subDetails}> <span> - </span>{budgetOptions.find(b => b.key === budget)?.label}</div>
                  </div>
                </div>
                <div className={css.homEuipped}>
                  <p className={css.lisHeading}>
                    <FormattedMessage id="listingPageCrousal.homeTitle" />
                  </p>
                  <div className={css.homEuippedDiv}>
                    {filteredAppliances.map((appliance, index) => (
                      <div className={css.productInput} key={index}>
                        <img src={tickgreen} />
                        {appliance.name} (x{appliance.count})
                      </div>
                    ))}
                  </div>
                </div>

              </div>

              <div className={css.detailsWrapperS2}>
                <div className={css.productBox}>
                  <p className={css.lisHeading}>
                    <FormattedMessage id="listingPageCrousal.preferenceTitle" />
                  </p>
                  <div className={css.listWrapper}>
                    <div className={css.listDetail}>
                      <div className={css.listingLeft}>
                        <FormattedMessage id="listingPageCrousal.brandTitle" />
                      </div>
                      <div className={css.subDetails}>  <span> - </span>{brandOptionsIRF.find(b => b.key === brand)?.label}</div>
                    </div>
                    <div className={css.listDetail}>
                      <div className={css.listingLeft}>
                        <FormattedMessage id="listingPageCrousal.budgetTitle" />
                      </div>
                      <div className={css.subDetails}>  <span> - </span>{budgetOptions.find(b => b.key === budget)?.label}</div>
                    </div>
                    <div className={css.listDetail}>
                      <div className={css.listingLeft}>
                        <FormattedMessage id="listingPageCrousal.fuelTitle" />
                      </div>
                      <div className={css.subDetails}>  <span> - </span>{fuelSourceOptions.find(f => f.key === fuelSource)?.label}</div>
                    </div>
                    <div className={css.listDetail}>
                      <div className={css.listingLeft}>
                        <FormattedMessage id="listingPageCrousal.fueltypeTitle" />
                      </div>
                      <div className={css.subDetails}>  <span> - </span>{coolingTypes.find(t => t.key === type)?.label}</div>
                    </div>
                  </div>
                </div>

                <div className={css.mobileHeading}>
                  <H4 as="h1" className={css.orderPanelTitle}>
                    <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                  </H4>
                </div>
                <SectionTextMaybe text={description} showAsIngress />
                <SectionDetailsMaybe
                  publicData={publicData}
                  metadata={metadata}
                  listingConfig={listingConfig}
                  intl={intl}
                />
                {listingConfig.listingFields.reduce((pickedElements, config) => {
                  const { key, enumOptions, scope = 'public' } = config;
                  const value =
                    scope === 'public' ? publicData[key] : scope === 'metadata' ? metadata[key] : null;
                  const hasValue = value !== null;
                  return hasValue && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                    ? [
                      ...pickedElements,
                      <SectionMultiEnumMaybe
                        key={key}
                        heading={config?.showConfig?.label}
                        options={createFilterOptions(enumOptions)}
                        selectedOptions={value}
                      />,
                    ]
                    : hasValue && config.schemaType === SCHEMA_TYPE_TEXT
                      ? [
                        ...pickedElements,
                        <SectionTextMaybe key={key} heading={config?.showConfig?.label} text={value} />,
                      ]
                      : pickedElements;
                }, [])}

                {/* {userType === 'IN' ? */}
                  <div className={css.inPictureDiv}>
                    <H4 as="h1" className={css.orderPanelTitle}>
                      <h3>Installation Pictures</h3>
                    </H4>
                    <div className={css.inPictures}>
                      {imageURLs.map((image, index) => <div className={css.inPicture}>
                        <img key={index} src={image}></img>
                      </div>)
                      }
                    </div>
                  </div>
                  {/* : null} */}
              </div>
            </div>


            {/* <ResponsiveImage image={imageURLs}/> */}
            {/* <SectionMapMaybe
              geolocation={geolocation}
              publicData={publicData}
              listingId={currentListing.id}
              mapsConfig={config.maps}
            /> */}

            {reviews === 0 ? (
              <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
            ) : null}

            {/* <SectionAuthorMaybe
              title={title}
              listing={currentListing}
              authorDisplayName={authorDisplayName}
              onContactUser={onContactUser}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
            /> */}
          </div>

          {userType === 'IN' && !hasPendingBids ? (
            <div className={css.orderColumnForProductLayout}>
              <SectionMapMaybe
                geolocation={geolocation}
                publicData={publicData}
                listingId={currentListing.id}
                mapsConfig={config.maps}
              />
              <OrderPanel
                variant="bid"
                sendInquiryInProgress={sendInquiryInProgress}
                className={css.productOrderPanel}
                listing={currentListing}
                isOwnListing={isOwnListing}
                onSubmit={handleOrderSubmit}
                authorLink={
                  <NamedLink
                    className={css.authorNameLink}
                    name="ListingPage"
                    params={params}
                    to={{ hash: '#author' }}
                  >
                    {authorDisplayName}
                  </NamedLink>
                }
                title={
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                }
                titleDesktop={
                  <H4 as="h1" className={css.orderPanelTitle}>
                    <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                  </H4>
                }
                author={ensuredAuthor}
                onManageDisableScrolling={onManageDisableScrolling}
                onContactUser={onContactUser}
                onSubmitInquiry={onSubmitInquiry}
                monthlyTimeSlots={monthlyTimeSlots}
                onFetchTimeSlots={onFetchTimeSlots}
                onFetchTransactionLineItems={onFetchTransactionLineItems}
                lineItems={lineItems}
                fetchLineItemsInProgress={fetchLineItemsInProgress}
                fetchLineItemsError={fetchLineItemsError}
                marketplaceCurrency={config.currency}
                dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                marketplaceName={config.marketplaceName}
              />
            </div>
          ) : userType === 'IN' ? <p className={css.someoneText}>Someone from your company has already bid on this IRF and you can't bid on it again until the bid has been Accepted, Declined or Expired. To review the bid please visit to Inbox</p> : null}
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onCreateNewTransaction: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onCreateNewTransaction: (listingId, bidResponse) =>
    dispatch(createNewTransaction(listingId, bidResponse)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(connect(mapStateToProps, mapDispatchToProps))(EnhancedListingPage);

export default ListingPage;